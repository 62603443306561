import React, { useState, useEffect } from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import TabContent from "./components/TabContent";
import EmailForm from "./components/EmailForm";
import FAQSection from "./components/FAQSection";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TOS from "./components/TOS";

function App() {
  const [activeTab, setActiveTab] = useState("home");
  const [obeyedURLDirective, setObeyedURLDirective] = useState(false);



  
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const switchToThankYou = () => {
    setActiveTab("thankyou");
  };

  //Check if url ends in privacy and if so, switch to privacy tab
  if (window.location && window.location.href && window.location.href.endsWith("privacy") && !obeyedURLDirective) {
    setActiveTab("privacy");
    setObeyedURLDirective(true);
  }

  //Check if url ends in tos and if so, switch to tos tab
  if (window.location && window.location.href && window.location.href.endsWith("tos") && !obeyedURLDirective) {
    setActiveTab("tos");
    setObeyedURLDirective(true);
  }

  return (
    <div className="site-container">
      <NavBar onTabChange={handleTabChange} />
      <div className="container">
        <TabContent id="home" active={activeTab === "home"}>
          <div className="textblock">
            <h1>Rediscover Balance in Motherhood</h1>
            <h2>MeTime – The end of unacknowledged mental labor.</h2>
            <p>
              Imagine a life where the mental load of motherhood is lifted.
              Where organizing family schedules, managing finances, and keeping
              up with social connections are seamlessly taken care of. Welcome
              to MeTime – a revolutionary AI assistant designed for today's
              busy moms.
            </p>
            <p>
              With MeTime, gain the freedom to focus on what truly matters –
              your family, your passions, and yourself. Experience the joy of a
              well-organized life, and embrace the peace that comes with it.
            </p>
            <button onClick={() => setActiveTab("signup")} className="button">
              Join the Early Access
            </button>
            <div className="separator"></div>
            <h3>Embrace Comprehensive Support</h3>
            <p>
              MeTime is not just about easing daily chores; it's a holistic
              approach to managing your household. From streamlining family
              schedules to offering insightful financial oversight and
              simplifying your social media interactions, MeTime is designed
              to lighten your mental load across all fronts. Enjoy the clarity
              and calm that comes with organized finances and a connected social
              life, all integrated seamlessly with your family management.
            </p>
            <div className="separator"></div>
            <h3>Your Personal Assistant for Every Aspect of Life</h3>
            <p>
              <img
                src="MeTimeIcon1.png"
                width="10%"
                alt=""
                className="bulletImage"
              />{" "}
              <strong>Total Household Organization</strong>
              <br /> Calendars, tasks, and reminders in one place.
            </p>
            <p>
              <img
                src="MeTimeIcon2.png"
                width="10%"
                alt=""
                className="bulletImage"
              />{" "}
              <strong>Financial Management</strong>
              <br /> Simplified tracking and insights for family finances.
            </p>
            <p>
              <img
                src="MeTimeIcon3.png"
                width="10%"
                alt=""
                className="bulletImage"
              />
              <strong>Social Media Mastery</strong>
              <br /> Efficient management of your digital social life.
            </p>
          </div>
        </TabContent>

        <TabContent id="thankyou" active={activeTab === "thankyou"}>
          <div className="textblock">
            <h2>Thank you for subscribing!</h2>
          </div>
        </TabContent>

        <TabContent id="about" active={activeTab === "about"}>
          <div className="textblock">
            <h2>About Us</h2>
            <p>
              At MeTime, innovation and empathy aren't just concepts; they are
              the cornerstones of our existence. Led by Oren Montano, our team
              embodies a forward-thinking spirit that is deeply rooted in a
              journey of transformation and resilience. Oren, a dynamic software
              engineer with over 30 years of experience, began her most personal
              and empowering journey just a year ago, transitioning to live as
              her authentic self as a transgender woman. This profound change
              not only marks a new chapter in her life but also underscores the
              ethos of MeTime.
            </p>
            <div className="separator"></div>
            <h3>Our Mission</h3>
            <p>
              MeTime is dedicated to alleviating the often invisible yet
              significant load carried by mothers. We focus
              on creating an AGI that addresses the unspoken challenges of
              motherhood. Our goal is simple: to give back the time and mental
              energy that mothers tirelessly invest in their families.
            </p>
            <div className="separator"></div>
            <h3>Our Philosophy</h3>
            <p>
              Distinct from other AI/AGI initiatives, MeTime champions minimal
              interaction. We believe in creating technology that works
              efficiently in the background, handling tasks and easing burdens
              without requiring constant user engagement. Our success is
              measured by how effectively we can make our users' daily
              challenges disappear, giving them more time to cherish life's
              moments.
            </p>
            <div className="separator"></div>
            <h3>Meet the Team</h3>
            <p>
              <strong>Oren Montano, CEO:</strong>
              <br /> With a rich career spanning three decades and a life marked
              by courage and authenticity, Oren's transition a year ago adds a
              unique dimension to her leadership at MeTime. Her expertise in
              software engineering is matched by her commitment to advancing
              human happiness and well-being.
            </p>
            <p>
              <strong>Lexy Thompson:</strong>
              <br /> As a therapist, Lexy offers invaluable insights into the
              human psyche. Her guidance helps keep MeTime grounded, empathetic,
              and aligned with the real needs of our users.
            </p>
            <div className="separator"></div>
            <h3>Looking Forward</h3>
            <p>
              As MeTime grows, so does our commitment to creating technology
              that makes a tangible difference. We are on a mission to transform
              the everyday lives of mothers, giving them the gift of time. Join us in this
              exciting venture and experience the change we're bringing to the
              world.
            </p>
            <div className="separator"></div>
            <h2>We are MeTime. Rediscover your time.</h2>
          </div>
        </TabContent>

        <TabContent id="features" active={activeTab === "features"}>
          <div className="textblock">
            <h2>Our Features</h2>
            <p>
              Welcome to MeTime - Your personal AI companion designed to
              simplify your life through smart email and texting integration.
              Here's how we can help:
            </p>

            <div class="feature">
              <h3>Email and Text-Based Task Management</h3>
              <p>
                Effortlessly manage your daily tasks via email and text.
                MeTime organizes and prioritizes your to-dos, sends
                reminders, and keeps you on track.
              </p>
            </div>

            <div class="feature">
              <h3>Intelligent Meal Planning</h3>
              <p>
                Receive personalized meal suggestions and shopping lists
                directly through text. MeTime helps plan your meals based on
                preferences and dietary needs.
              </p>
            </div>

            <div class="feature">
              <h3>School and Homework Tracking</h3>
              <p>
                Stay updated on your child’s school activities and homework
                through automated email alerts. Ensure nothing gets missed in
                your child's education.
              </p>
            </div>

            <div class="feature">
              <h3>Event and Appointment Reminders</h3>
              <p>
                Get timely reminders for all your important events and
                appointments via text and email. MeTime ensures you’re
                always prepared.
              </p>
            </div>

            <div class="feature">
              <h3>Coming Soon: Direct Financial Management</h3>
              <p>
                In the future, look forward to direct integration with financial
                tools like Quicken for seamless management of your finances.
              </p>
            </div>

            <div class="feature">
              <h3>And More...</h3>
              <p>
                As MeTime evolves, expect more features that will continue
                to simplify and enhance different aspects of your life.
              </p>
            </div>
          </div>
        </TabContent>

        <TabContent id="finance" active={activeTab === "finance"}>
          <div className="textblock">
            <h2>Family Finance</h2>
    <p>Imagine a future where MeTime not only manages your daily routine but also takes care of your financial management needs. Here’s a glimpse of what’s coming:</p>

    <div class="feature">
        <h3>Automated Budgeting & Expense Tracking</h3>
        <p>Get personalized budgeting advice and monitor your expenses effortlessly. MeTime makes managing finances simple and insightful.</p>
    </div>

    <div class="feature">
        <h3>Bill Payment Automation</h3>
        <p>Never miss a bill payment again. Our AI will schedule and manage your bill payments, ensuring you're always up to date.</p>
    </div>

    <div class="feature">
        <h3>Comprehensive Financial Health Reports</h3>
        <p>Receive monthly summaries of your financial health, along with actionable tips to improve your financial wellbeing.</p>
    </div>
          </div>
        </TabContent>

        <TabContent id="testimonials" active={activeTab === "testimonials"}>
          <div className="textblock">
            <h2>Testimonials</h2>
            <p>
              "MeTime is a lifesaver! It has helped me keep track of my
              family's schedule and finances, and has saved me countless hours
              of work. I don't know how I lived without it!"
            </p>
            <p>- Jane Doe, mother of two</p>
          </div>
        </TabContent>

        <TabContent id="faq" active={activeTab === "faq"}>
          <div className="textblock">
            <FAQSection />
          </div>
        </TabContent>

        <TabContent id="blog" active={activeTab === "blog"}>
          <div className="textblock">
            <h2>Blog</h2>
            <p>Coming soon!</p>
          </div>
        </TabContent>

        <TabContent id="contact" active={activeTab === "contact"}>
          <div className="textblock">
          <div class="contact-section">
    <h2>Contact Us</h2>
    <p>Have questions or need support? Feel free to reach out to us via email or through our social media channels.</p>
    
    <div class="email-contact">
        <p>Email us at: <a href="mailto:contact@MeTime.bot">contact@MeTime.bot</a></p>
    </div>

    <div class="social-contacts">
        <p>Alternatively, you can find us on:</p>
        <ul>
            <li><a href="https://twitter.com/metimebot">Twitter</a></li>
            <li><a href="https://www.facebook.com/metimebot">Facebook</a></li>
        </ul>
    </div>
</div>
          </div>
        </TabContent>

        <TabContent id="signup" active={activeTab === "signup"}>
          <div className="textblock">
            <h2>Sign Up</h2>
            <p>Sign up below to join the waitlist.</p>
            <EmailForm onSuccessfulSubmit={switchToThankYou} />
          </div>
        </TabContent>

        <TabContent id="privacy" active={activeTab === "privacy"}>
        <div className="textblock">
            <PrivacyPolicy />
          </div>
        </TabContent>

        <TabContent id="tos" active={activeTab === "tos"}>
        <div className="textblock">
            <TOS />
          </div>
        </TabContent>
      </div>
      <div className="footer">
        <p><a href="#privacy" onClick={() => setActiveTab("privacy")}>Privacy Policy</a>&nbsp;
        <a href="#tos" onClick={() => setActiveTab("tos")}>Terms of Service</a>
        </p>
      </div>
    </div>
  );
}

export default App;
